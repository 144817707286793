import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../store/auth.store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'auth',
      component: () => import('@/pages/Auth.vue'),
    },
    {
      path: '/minter/nfts',
      name: 'minter-nfts',
      component: () => import('@/pages/minter/Nfts.vue'),
    },
    {
      path: '/minter/nft/:tokenId',
      name: 'minter-nft',
      component: () => import('@/pages/minter/NftProfile.vue'),
      props: true
    },
    {
      path: '/minter/offers',
      name: 'minter-offers',
      component: () => import('@/pages/minter/Offers.vue'),
    },
    {
      path: '/minter/offer/:offerId',
      name: 'minter-offer',
      component: () => import('@/pages/minter/Offer.vue'),
      props: true
    },
    {
      path: '/minter/select-nft',
      name: 'minter-select-nft',
      component: () => import('@/pages/minter/NftCardsForOffer.vue'),
    },
    {
      path: '/minter/create-offer/:tokenId',
      // path: '/minter/create-offer',
      name: 'minter-create-offer',
      component: () => import('@/pages/minter/CreateNewOffer.vue'),
      props: true
    },
    {
      path: '/minter/license-agreement',
      name: 'minter-license-agreement',
      component: () => import('@/components/LicenseAgreement.vue'),
    },
    {
      path: '/minter/license-preview',
      name: 'minter-license-preview',
      component: () => import('@/pages/minter/LicensePreview.vue'),
    },
    {
      path: '/minter/mint/step-one',
      name: 'minter-mint-step-one',
      component: () => import('@/pages/minter/MintStepOne.vue'),
    },
    {
      path: '/minter/mint/step-two',
      name: 'minter-mint-step-two',
      component: () => import('@/pages/minter/MintStepTwo.vue'),
    },
    {
      path: '/minter/mint/preview',
      name: 'minter-mint-preview',
      component: () => import('@/pages/minter/MintPreview.vue'),
    },

    {
      path: '/buyer/explorers',
      name: 'buyer-explorers',
      component: () => import('@/pages/buyer/Explorers.vue'),
    },
    {
      path: '/buyer/licenses',
      name: 'buyer-licenses',
      component: () => import('@/pages/buyer/Licenses.vue'),
    },
    {
      path: '/buyer/nfts',
      name: 'buyer-nfts',
      component: () => import('@/pages/buyer/Nfts.vue'),
    },
    {
      path: '/buyer/token-info',
      name: 'token-info',
      component: () => import('@/pages/buyer/TokenInfo.vue'),
    },
    {
      path: '/buyer/nft/:tokenId',
      name: 'buyer-nft',
      component: () => import('@/pages/buyer/TokenInfo.vue'),
      props: true
    },
    {
      path: '/buyer/license/:licenseId',
      name: 'buyer-license',
      component: () => import('@/pages/buyer/License.vue'),
      props: true
    },
    {
      path: '/buyer/my-license/:licenseId',
      name: 'buyer-my-license',
      component: () => import('@/pages/buyer/MyLicense.vue'),
      props: true
    },
  ]
})

// router.beforeEnter((to, from, next) => {
//   const authStore = useAuthStore();

//   console.log('check route', !authStore.user, from , to)

//   if (!authStore.user && to.path != '/') {
//     next({ name: 'auth' })
//     return
//   }
// })

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (!authStore.user && to.path != '/') {
    next({ name: 'auth' })
    return
  }

  // if (!window.location.href.endsWith('/')) {
  //   window.location.href += '/'
  // }

  next()
})



router.afterEach(() => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
})

export default router