import { createApp } from 'vue'
import App from './App.vue'
import { Buffer } from 'buffer'
import axios from "axios";
import VueAxios from "vue-axios";
import MasonryWall from "@yeger/vue-masonry-wall";
import VueApexCharts from "vue3-apexcharts";
import vSelect from 'vue-select'
import VueQRCodeComponent from 'vue-qr-generator'
import BaseIcon from '@/components/BaseIcon.vue'
import VuePdf from 'vue3-pdfjs'

import router from './router'
import { store } from './store'

import './assets/scss/style.scss'

export const afoofe = [1, 2 , 3]
const app = createApp(App);


let currPriceCoefficient = 0
axios.get('https://licensing-djooky-testnet.v-art.digital:3000/testing/api/v1/currency').then((response) => {
    currPriceCoefficient = response.data
    
});

window.Buffer = Buffer

app.use(store)
app.use(router)
app.use(VueAxios, axios);
app.use(MasonryWall);
app.use(VuePdf);
app.use(VueApexCharts);
app.component('base-icon', BaseIcon)
app.component('v-select', vSelect)
app.component('qr-code', VueQRCodeComponent)
app.provide('axios', app.config.globalProperties.axios)
app.mount("#app");

app.config.globalProperties.$filters = {
    priceToNear(price) {
        return (+price / (10**24)).toFixed(2)
    },
    priceNearToUsd(price) {
        return (+price / (10**24) * currPriceCoefficient?.near?.usd).toFixed(2)
    },
    lowestPriceNear(array) {
        let lowestPrice = null
        array.forEach(item => {
            if (!lowestPrice || lowestPrice > item.price) {
                lowestPrice = item.price
            }
        })

        return (+lowestPrice / (10**24)).toFixed(2)
    },
    highestPriceNear(array) {
        let lowestPrice = null
        array.forEach(item => {
            if (!lowestPrice || lowestPrice < item.price) {
                lowestPrice = item.price
            }
        })

        return (+lowestPrice / (10**24)).toFixed(2)
    },
    lowestPriceUsd(array) {
        let lowestPrice = null
        array.forEach(item => {
            if (!lowestPrice || lowestPrice > item.price) {
                lowestPrice = item.price
            }
        })

        return (+lowestPrice / (10**24) * currPriceCoefficient?.near?.usd).toFixed(2)
    },
    highestPriceUsd(array) {
        let lowestPrice = null
        array.forEach(item => {
            if (!lowestPrice || lowestPrice < item.price) {
                lowestPrice = item.price
            }
        })

        return (+lowestPrice / (10**24) * currPriceCoefficient?.near?.usd).toFixed(2)
    }
  }


  
