import { defineStore } from 'pinia'

export const useCommonStore = defineStore({
  id: 'Common',
  state: () => ({
    loader: false,
    modal: false,
    hidden: false,
    windowWidth: 10,
    dialogParamsData: {
      open: false,
      confirm: false
      // component: undefined,
      // maxWidth: 544,
      // data: {}
    },
    licenseTypes: ['', 'Exclusive', 'Non-exclusive', 'Sole'],
    licenseGoals: ['', 'Commercial use', 'Creative', 'Demonstration'],
    nftStatuses: ['On sale', 'Not on sale', 'Offers available'],
    licenseRegions: ['CV', 'AD', 'AE', 'EN', 'TW', 'TZ', 'UA', 'ZW']
  }),
  getters: {
    dialogParams: state => state.dialogParamsData,
    isDesktop: state => state.windowWidth >= 768,
    windowWidthLess1300: state => state.windowWidth <= 1300,
    isMobile: state => state.windowWidth < 768,
  },

  actions: {
    async bindUser() {

    },
    initNewDialog(payload) {
      payload.open = true;
      payload.confirm = false;

      this.$patch({
        dialogParamsData: payload
      })
    },
    confirmDialog() {
      this.$patch({
        dialogParamsData: {
          confirm: true,
          open: false
        }
      })
    },
    closeDialog() {
      this.$patch({
        dialogParamsData: {
          open: false
        }
      })
    },
  }
})
